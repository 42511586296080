<template>
    <div>
        <breadcrumbs title="about us" />

        <!-- CONTENT -->
        <div class="section section-border">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-md-4">
                        <img src="https://via.placeholder.com/600x800" alt="rud" class="img-responsive img-rounded">
                    </div>

                    <div class="col-sm-8 col-md-8">
                        <h3>My name is Renata A. Sasmita, I am 35 years old. I am a Personal Trainer, coach and
                            mentor</h3>
                        <p>Vestibulum vitae eros a nibh iaculis fringilla lobortis vitae est. Etiam vitae consequat
                            arcu. Mauris laoreet purus gravida nisl ultrices condimentum. Praesent nec sapien iaculis,
                            posuere diam vel, imperdiet elit. Donec porttitor ac ligula et facilisis. Morbi sollicitudin
                            maximus neque eget mollis. Maecenas vitae bibendum purus, vel pellentesque magna. </p>
                        <p>Integer vestibulum erat at odio efficitur iaculis. Suspendisse sodales neque sit amet sem
                            finibus, eget posuere ipsum vulputate. Curabitur urna felis varius id massa quis
                            lobortis..</p>
                        <p><img src="https://via.placeholder.com/180x93" alt=""></p>
                    </div>
                </div>
                <div class="spacer-50"></div>
                <div class="row gallery-1">
                    <div class="col-sm-3 col-md-3">
                        <a href="https://via.placeholder.com/600x350" title="Gallery 1"><img
                                src="https://via.placeholder.com/600x350" alt="rud" class="img-responsive img-rounded"></a>
                    </div>
                    <div class="col-sm-3 col-md-3">
                        <a href="https://via.placeholder.com/600x350" title="Gallery 2"><img
                                src="https://via.placeholder.com/600x350" alt="rud" class="img-responsive img-rounded"></a>
                    </div>
                    <div class="col-sm-3 col-md-3">
                        <a href="https://via.placeholder.com/600x350" title="Gallery 3"><img
                                src="https://via.placeholder.com/600x350" alt="rud" class="img-responsive img-rounded"></a>
                    </div>
                    <div class="col-sm-3 col-md-3">
                        <a href="https://via.placeholder.com/600x350" title="Gallery 4"><img
                                src="https://via.placeholder.com/600x350" alt="rud" class="img-responsive img-rounded"></a>
                    </div>
                </div>

            </div>
        </div>

        <!-- ABOUT -->
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6">

                        <h2 class="section-heading">
                            ABOUT US
                        </h2>
                        <h1 class="jumbolead reset-section-heading">We have the experience with combine quality
                            workmanship.</h1>
                        <p>We provide a professional dignissim at cursus elefeind norma arcu. Pellentesque accumsan est
                            in tempus etos ullamcorper sem quam suscipit lacus maecenas tortor. Suspendisse gravida
                            ornare non mattis velit rutrum modest.</p>

                    </div>
                    <div class="col-sm-6 col-md-6">
                        <div class="vidimg">
                            <div class="play-vid">
                                <a class="popup-youtube" href="https://www.youtube.com/watch?v=JGYuCRYFxew"><span
                                        class="fa fa-play fa-2x playvid"></span></a>
                            </div>
                            <img src="https://via.placeholder.com/900x600" alt="" class="img-responsive">
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- CTA -->
        <div class="section cta bg-overlay-1">
            <div class="container">

                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <div class="cta-info">
                            <i class="fa fa-leaf fa-3x"></i>
                            <h3>We're here to help you reach your goal of coaching people to reach their full
                                potential.</h3>
                            <p>We provide innovative solutions for sustainable progress. Our professional team works to
                                increase productivity and cost effectiveness on the market</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- STATS -->
        <div class="section statistic bg-overlay-2">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-md-4">
                        <h2 class="jumbolead">We Have Great Achivment To Show!!</h2>
                    </div>
                    <div class="col-sm-8 col-md-8">
                        <div class="row">

                            <div class="col-sm-3 col-md-3">
                                <div class="counter-1">
                                    <div class="counter-number">
                                        1238
                                    </div>
                                    <div class="counter-title">Satisfied Customers</div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-md-3">
                                <div class="counter-1">
                                    <div class="counter-number">
                                        850
                                    </div>
                                    <div class="counter-title">People Across</div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-md-3">
                                <div class="counter-1">
                                    <div class="counter-number">
                                        10
                                    </div>
                                    <div class="counter-title">Years Experience</div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-md-3">
                                <div class="counter-1">
                                    <div class="counter-number">
                                        232
                                    </div>
                                    <div class="counter-title">Persons Mentored</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- HISTORY -->
        <div class="section ">
            <div class="container">
                <div class="col-sm-12 col-md-12">
                    <h2 class="section-heading">
                        TIMELINE HISTORY
                    </h2>
                </div>
                <div class="col-sm-12 col-md-12">
                    <div class="history-2">
                        <div class="timeline__item">
                            <div class="media"><img src="https://via.placeholder.com/600x400" class="img-responsive"
                                                    alt="Company History"></div>
                            <div class="aksen"></div>
                            <div class="text">
                                <div class="year">1983</div>
                                <div class="title">Early Stages</div>
                                <p>This was the time when we started our company. We had no idea how far we would go, we
                                    werenâ€™t even sure that we would be able to survive for a few years. What drove us
                                    to start the company was the understanding that we could provide a service no one
                                    else was providing.</p>
                            </div>
                        </div>
                        <div class="timeline__item">
                            <div class="media"><img src="https://via.placeholder.com/600x400" class="img-responsive"
                                                    alt="Company History"></div>
                            <div class="aksen"></div>
                            <div class="text">
                                <div class="year">1994</div>
                                <div class="title">Career Start</div>
                                <p>This was the time when we started our company. We had no idea how far we would go, we
                                    werenâ€™t even sure that we would be able to survive for a few years. What drove us
                                    to start the company was the understanding that we could provide a service no one
                                    else was providing.</p>
                            </div>
                        </div>
                        <div class="timeline__item">
                            <div class="media"><img src="https://via.placeholder.com/600x400" class="img-responsive"
                                                    alt="Company History"></div>
                            <div class="aksen"></div>
                            <div class="text">
                                <div class="year">2009</div>
                                <div class="title">Award Winning</div>
                                <p>This was the time when we started our company. We had no idea how far we would go, we
                                    werenâ€™t even sure that we would be able to survive for a few years. What drove us
                                    to start the company was the understanding that we could provide a service no one
                                    else was providing.</p>
                            </div>
                        </div>
                        <div class="timeline__item">
                            <div class="media"><img src="https://via.placeholder.com/600x400" class="img-responsive"
                                                    alt="Company History"></div>
                            <div class="aksen"></div>
                            <div class="text">
                                <div class="year">2015</div>
                                <div class="title">Best Selling Author</div>
                                <p>This was the time when we started our company. We had no idea how far we would go, we
                                    werenâ€™t even sure that we would be able to survive for a few years. What drove us
                                    to start the company was the understanding that we could provide a service no one
                                    else was providing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
        components: {
            breadcrumbs: () => import('@/components/layout/Breadcrumb'),
        },
    }
</script>
